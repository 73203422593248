import $ from 'jquery'; 
import { useEffect } from "react";
import Commanmodel from "./Allproductmodel";
import './AllProduct.css';

const Pickles = () =>{
    useEffect(()=>{
        $('.test').on('click',function()
        {
        let element = $(this);        
        var img =  $(this).find('img').attr('src');
        var head =  $(this).find('h3').text();
        var data =  $(this).find('ul').html();
        var p_d = $(this).find('.p_def').html();       
        $('.modal-title').text(head);
        $('#model_img').attr('src',img);
        $('.model_data').html(data);        
        });
  
    },[])

 return(
    <>
        <section>
      <div className="container-fluid p-0" >
        <img src="images/banner/pickles.jpg" className="img-fluid banner"/>
        
      </div>
    </section>
    
    <main id="main">
      <div className="section-title" data-aos="fade-up">
        <h2>All Pickles</h2>
      </div>
      
      <section id="featured" className="featured">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-4" >
              <a href="#exampleModal" data-toggle="modal" className="test">
                <img alt="picture" src="images/Pickles/mangopickles.jpg"
                className="img-fluid resize rounded"/>
                <h3 className="text-center my-3 text-uppercase"> Mango </h3>
                <ul className="d-none">
                  <li>Mango pickle is a traditional indian condiment made with raw mangoes, spices and oil</li>
                  <li>In India pickles are relished as a side in every meal</li>
                  <li>Fat- 19.73%</li><li>Protein- 1.40g/100g</li><li>Carbohydrates- 20g/100g</li><li>Country of Origin- India</li><li>Min Order Qty- 1Mt Ton</li>
                </ul>
              </a>
            </div>
            <div className="col-sm-12 col-md-4" >
              <a href="#exampleModal" data-toggle="modal" className="test">
                <img alt="picture" src="images/Pickles/lemon_pickle.png"
                className="img-fluid resize rounded"/>
                <h3 className="text-center my-3 text-uppercase"> Lemon Pickles </h3>
                <ul className="d-none">
                  <li>Home remedy for stomach disorders</li>
                  <li>Lemon contains many healthy nutrients</li>
                  <li>Pickles are good for health when consumed in moderation Builds immunity</li>
                  <li>Nutrition Fact-Protien- 0.7%</li><li>Carbohydrates- 20g/100g</li><li>Country of Origin- India</li><li>Min Order Qty- 1Mt Ton
</li>
                </ul>
              </a>
            </div>
            <div className="col-sm-12 col-md-4" >
              <a href="#exampleModal" data-toggle="modal" className="test">
                <img alt="picture" src="images/Pickles/Mixpickles.png"
                className="img-fluid resize rounded"/>
                <h3 className="text-center my-3 text-uppercase"> Mix Pickles </h3>
                <ul className="d-none">
                  <li> Mix Pickles</li>
                  <li>Country of Origin- India</li>
                  <li>Min Order Qty- 1Mt Ton</li>
                </ul>
              </a>
            </div>         
            <div className="col-sm-12 col-md-4" >
              <a href="#exampleModal" data-toggle="modal" className="test">
                <img alt="picture" src="images/Pickles/greenChillipickle.jpg"
                className="img-fluid resize rounded"/>
                <h3 className="text-center my-3 text-uppercase"> Green Chilli Pickle </h3>
                <ul className="d-none">
                  <li>Treats the common cold as it stimulates lung function,thins the mucus to allow you to cough it up</li>
                  <li>High level of vitamin C present is a potent antioxidant, which speeds up the healing process of several illnesses and fights Inflammation</li>
                  <li>Country of Origin- India</li>
                  <li>Min Order Qty- 1Mt Ton</li>
                </ul>
              </a>
            </div>            
            </div>
            </div>
          </section>
          <Commanmodel/>
          </main>
    </>
 );   
}

export default Pickles;