import $ from 'jquery'; 
import { useEffect } from "react";
import Commanmodel from "./Allproductmodel";
import './AllProduct.css';
const Cereals = () =>{
    useEffect(()=>{
        $('.test').on('click',function()
        {
        let element = $(this);        
        var img =  $(this).find('img').attr('src');
        var head =  $(this).find('h3').text();
        var data =  $(this).find('ul').html();
        var p_d = $(this).find('.p_def').html();       
        $('.modal-title').text(head);
        $('#model_img').attr('src',img);
        $('.model_data').html(data);        
        });
  
    },[])
    return(
        <>
         <section>
      <div className="container-fluid p-0">
        <img src="images/banner/grains-food-mix-wooden-background.jpg" className="img-fluid banner"/>
        
      </div>
    </section>
    
    <main id="main">
      <div className="section-title" data-aos="fade-up">
        <h2 className="text-uppercase">Cereals</h2>
      </div>
      
      <section id="featured" className="featured">
        <div className="container">
          <div className="row">
             <div className="col-sm-12 col-md-4" >
              <a href="#exampleModal" data-toggle="modal" className="test">
                <img alt="picture" src="images/CEREALS/BASMATI.png"
                className="img-fluid resize rounded"/>
                <h3 className="text-center my-3 text-uppercase"> Basmati Rice </h3>
                <ul className="d-none">
                  <li>Basmati rice is typically high in carbs and micronutrients like thiamine, folate, and selenium</li>
                  <li>Country of Origin- India</li>
                  <li>Order More Than 100kg</li>
                </ul>
              </a>
            </div>
            <div className="col-sm-12 col-md-4" >
              <a href="#exampleModal" data-toggle="modal" className="test">
                <img alt="picture" src="images/CEREALS/NONBASMATI.jpg"
                className="img-fluid resize rounded"/>
                <h3 className="text-center my-3 text-uppercase"> Non Basmati Rice </h3>
                <ul className="d-none">
                  <li>White rice is high in minerals such as calcium and iron; it is also rich in vitamins, such as niacin, vitamin D, thiamine and riboflavin.</li>
                  <li>Rice has numerous health benefits; it is an instant source of energy for the body</li>
                  <li>Country of Origin- India</li>
                  <li>Min Order Qty- 1Mt Ton.</li>
                </ul>
              </a>
            </div>
              <div className="col-sm-12 col-md-4" >
              <a href="#exampleModal" data-toggle="modal" className="test">
                <img alt="picture" src="images/CEREALS/wheat.jpg"
                className="img-fluid resize rounded"/>
                <h3 className="text-center my-3 text-uppercase"> Wheat </h3>
                <ul className="d-none">
                  <li>Wheat has a rich nutritional value and it is a rich supply of vitamins and protein.</li>
                  <li> It is highly nutritious and is rich in micronutrients such as carbohydrates, proteins, minerals and vitamins</li>
                  <li>Country of Origin- India</li>
                  <li>Min Order Qty- 1Mt Ton</li>
                </ul>
              </a>
            </div>
            <div className="col-sm-12 col-md-4" >
              <a href="#exampleModal" data-toggle="modal" className="test">
                <img alt="picture" src="images/CEREALS/bajari.jpg"
                className="img-fluid resize rounded"/>
                <h3 className="text-center my-3 text-uppercase">Pearl Millet/Bajara </h3>
                <ul className="d-none">                  
                  <li>Pearl millet is a “high-energy” cereal that contains carbohydrates, protein, and fat, rich in vitamins B and A, high in calcium, iron, and zinc </li><li> also contains potassium, phosphorus, magnesium, zinc, copper, and manganese</li>
                  <li>Country of Origin-India</li>
                  <li>Min Order Qty- 1Mt Ton</li>
                </ul>
              </a>
            </div>
            <div className="col-sm-12 col-md-4" >
              <a href="#exampleModal" data-toggle="modal" className="test">
                <img alt="picture" src="images/CEREALS/Nachani.png"
                className="img-fluid resize rounded"/>
                <h3 className="text-center my-3 text-uppercase">Finger Millet/Nachani  </h3>
                <ul className="d-none">
                  <li>The vitamins, minerals, and fiber found in finger millet can provide important health benefits.</li>
                  <li>The potassium found in finger millet can help keep your kidneys and heart functioning properly. </li>                 
                  <li>Finger millet is also an excellent source of B vitamins </li>
                  <li> Country of Origin- India</li>
                  <li>Min Order Qty- 1Mt Ton</li>
                </ul>
              </a>
            </div>         
          </div>
          </div>          
          </section> 
          <Commanmodel />
          </main>
        </>
    );
}

export default Cereals;