import $ from 'jquery'; 
import { useEffect } from "react";
import Commanmodel from "./Allproductmodel";
import './AllProduct.css';


const Sauce = () =>{
    useEffect(()=>{
        $('.test').on('click',function()
        {
        let element = $(this);        
        var img =  $(this).find('img').attr('src');
        var head =  $(this).find('h3').text();
        var data =  $(this).find('ul').html();
        var p_d = $(this).find('.p_def').html();       
        $('.modal-title').text(head);
        $('#model_img').attr('src',img);
        $('.model_data').html(data);        
        });
  
    },[])

    return(
        <>
         <section>
      <div className="container-fluid p-0">
        <img src="images/banner/vegetables-4457145_1920.jpg" className="img-fluid banner"/>
        
      </div>
    </section>
    
    <main id="main">
      <div className="section-title" data-aos="fade-up">
        <h2>All Sauces</h2>
      </div>
      
      <section id="featured" className="featured">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-4" >
              <a href="#exampleModal" data-toggle="modal" className="test">
                <img alt="picture" src="images/Sauce/tomatosauces.jpg"
                className="img-fluid resize rounded"/>
                <h3 className="text-center my-3 text-uppercase"> Tomato Sauce </h3>
                <ul className="d-none">
                  <li>Tomatoes are the major dietary source of the antioxidant lycopene, which has been linked to many health benefits, including reduced risk of heart disease and cancer.</li>
                  <li>They are also a great source of vitamin C, potassium, folate, and vitamin K.</li>
                  <li>Country of Origin- India</li>
                  <li>Min Order Qty- 1Mt Ton</li>
                </ul>
              </a>
            </div>
            <div className="col-sm-12 col-md-4" >
              <a href="#exampleModal" data-toggle="modal" className="test">
                <img alt="picture" src="images/Sauce/redchillisauces1.jpg"
                className="img-fluid resize rounded"/>
                <h3 className="text-center my-3 text-uppercase"> Red Chilli Sauce </h3>
                <ul className="d-none">
                  <li>Red Chilli Sauce refers to a spicy sauce made from red chilli peppers and other ingredient together with it</li>
                  <li>Country of Origin-India</li>
                  <li>Min Order Qty- 1 Mt Ton</li>                  
                </ul>
              </a>
            </div>
            <div className="col-sm-12 col-md-4" >
              <a href="#exampleModal" data-toggle="modal" className="test">
                <img alt="picture" src="images/Sauce/greenchillisauce.png"
                className="img-fluid resize rounded"/>
                <h3 className="text-center my-3 text-uppercase"> Green Chilli Sauce </h3>
                <ul className="d-none">
                  <li>Green Chilli Sauce refers to a spicy sauce made from green chilli peppers and other ingredient together with it</li>
                  <li>Chilli Sauce is low in fat content & is good source of Vitamin A, Vitamin C, iron & manganese.</li>
                  <li>Country of Origin- India</li>
                  <li>Min Order Qty- 1Mt Ton</li>
                </ul>
              </a>
            </div>           
          </div>
          </div>
          </section>
         
            <Commanmodel/>
          </main>
        </>
    );
}

export default Sauce;