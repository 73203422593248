import Products from './Products';
import Banner from './banner';

function Home(){
    return(       
      <div>
        <Banner/>
        <Products />
      </div>
    );
}

export default Home;