import { Link } from "react-router-dom";

const Commanmodel = () =>{
    return(
        <div className="modal fade bd-example-modal-lg" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
              <div className="modal-dialog modal-lg">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title text-uppercase font-weight-bold" id="exampleModalLabel">strawberry</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="row">
                      <div className="col-sm-12 col-md-6">
                        <img alt="picture" id="model_img" src=""
                        className="img-fluid resize rounded"/>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div className="m-auto pt-4">
                          <h4 className="font-weight-bold">Specification</h4>
                          <ul className="model_data font-weight-bold">
                            
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>                     
                    <Link to='/' data-dismiss="modal" className="btn btn-primary"> Enquiry</Link>
                  </div>
                </div>
              </div>
            </div> 
    );
}

export default Commanmodel;