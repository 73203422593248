import { Link } from "react-router-dom";

const Product= (props) =>
{    
    return(  
          
            <div className="col-sm-12 col-md-3 text-center">               
              <Link to={props.link}>
                <img alt="picture" src={props.imgsrc}
                  className="img-fluid resize rounded" />
                <h3 className="text-center my-3 text-uppercase"> {props.title} </h3>
              </Link>
            </div>             
        
    );
}

export default Product;