import $ from 'jquery'; 
import { useEffect } from "react";
import Commanmodel from "./Allproductmodel";
import './AllProduct.css';
const Legume = () =>{
    useEffect(()=>{
        $('.test').on('click',function()
        {
        let element = $(this);        
        var img =  $(this).find('img').attr('src');
        var head =  $(this).find('h3').text();
        var data =  $(this).find('ul').html();
        var p_d = $(this).find('.p_def').html();       
        $('.modal-title').text(head);
        $('#model_img').attr('src',img);
        $('.model_data').html(data);        
        });
  
    },[])

    return(
        <>
        <section>
      <div className="container-fluid p-0">
        <img src="images/banner/Legume2.jpg" className="img-fluid banner"/>
        
      </div>
    </section>
    
    <main id="main">
      <div className="section-title" data-aos="fade-up">
        <h2>LEGUME</h2>
      </div>      
      <section id="featured" className="featured">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-4" >
              <a href="#exampleModal" data-toggle="modal" className="test">
                <img alt="picture" src="images/Legume/soyabean.jpg"
                className="img-fluid resize rounded"/>
                <h3 className="text-center my-3 text-uppercase"> Soyabean </h3>
                <ul className="d-none">
                  <li>Soybeans are a very rich source of plant-based protein and fat.</li><li>Nutrition Fact(Per 100gm)Potassium 1,797mg- 51%</li><li>Total Carbohydrate 30g- 10%</li><li>Dietary fiber 9g- 36%</li><li>Protein 36g- 72%</li><li>Vitamin C- 10%</li><li>Calcium- 27%</li><li>Iron- 87%</li><li>Vitamin B6- 20%</li><li>Magnesium-70%</li>
                  <li>Country of Origin- India</li>
                  <li>Min Order Qty- 1Mt Ton</li>
                </ul>
              </a>
            </div>
             <div className="col-sm-12 col-md-4" >
              <a href="#exampleModal" data-toggle="modal" className="test">
                <img alt="picture" src="images/Legume/chawali.png"
                className="img-fluid resize rounded"/>
                <h3 className="text-center my-3 text-uppercase"> Black-eyed peas </h3>
                <ul className="d-none">
                  <li>Black-eyed peas are high in protein and fiber along with micronutrients such as folate, copper and thiamines.</li>
                  <li>Nutrition Fact(Per 100gm)- Potassium 431mg- 12%</li><li>Total Carbohydrate 19g- 6%</li><li>Dietary fiber 5g- 20%</li><li>Protein 3g- 6%</li><li>Vitamin A- 16%</li><li>Vitamin C- 4%</li><li>Calcium- 12%</li><li>Iron- 6%</li><li>Vitamin B6- 5%</li><li>Magnesium- 12%</li>
                  <li>Country of Origin- India</li>
                  <li>Min Order Qty- 1Mt Ton</li>
                </ul>
              </a>
            </div>         
          </div>
          </div>
          </section>
          <Commanmodel />
          </main>
        </>
    );
}

export default Legume;