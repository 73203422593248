import './banner.css';

function Banner(){
    return(       
      <section id="hero">
       <div className="hero-container" id="home_page">
         <div id="heroCarousel" class="carousel slide carousel-fade" data-ride="carousel">
         <ol className="carousel-indicators" id="hero-carousel-indicators"></ol>
         <div className="carousel-inner" role="listbox">
         
           <div className="carousel-item banner1 active">
             <div className="carousel-container">
               <div className="carousel-content">
                 <h2 className="animate__animated animate__fadeInDown">Welcome to <span>Avighna Exim</span></h2>
                 <p className="animate__animated animate__fadeInUp">Avighna Exim is a Export-Import company that deals in the business of Good Quality Agricultural and Other Products.</p>               
               </div>
             </div>
           </div>
            
           <div className="carousel-item banner2" >
             <div className="carousel-container">
               <div className="carousel-content">
                 <h2 className="animate__animated fanimate__adeInDown">Avighna Exim <span>For Farmers</span></h2>
                 <p className="animate__animated animate__fadeInUp">We provide guidance to farmers for export their goods in terms of market selection, buyer finding, packaging and documentation.</p>                  
               </div>
             </div>
           </div>
         
           <div className="carousel-item banner3">
             <div className="carousel-container">
               <div className="carousel-content">
                  <h2 className="animate__animated fanimate__adeInDown">Avighna Exim <span>Services</span></h2>
                
                 <p className="animate__animated animate__fadeInUp">We provide Basic business set up services like Shop act Registration, Udyog Adhar Registration, GSTIN Registration, RCMC Certification, IEC Certificate.</p>               
               </div>
             </div>
           </div>
         </div>
         <a className="carousel-control-prev" href="#heroCarousel" role="button" data-slide="prev">
           <span className="carousel-control-prev-icon icofont-rounded-left" aria-hidden="true"></span>
           <span className="sr-only">Previous</span>
         </a>
         <a className="carousel-control-next" href="#heroCarousel" role="button" data-slide="next">
           <span className="carousel-control-next-icon icofont-rounded-right" aria-hidden="true"></span>
           <span className="sr-only">Next</span>
         </a>
       </div>
     </div>    
     </section>
    );
}

export default Banner;