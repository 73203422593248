import $ from 'jquery'; 
import { useEffect } from "react";
import Commanmodel from "./Allproductmodel";
import './AllProduct.css';

const Fruitjam = () =>{

    useEffect(()=>{
        $('.test').on('click',function()
        {
        let element = $(this);        
        var img =  $(this).find('img').attr('src');
        var head =  $(this).find('h3').text();
        var data =  $(this).find('ul').html();
        var p_d = $(this).find('.p_def').html();       
        $('.modal-title').text(head);
        $('#model_img').attr('src',img);
        $('.model_data').html(data);        
        });
  
    },[])

    return(
        <>
            <section>
      <div className="container-fluid p-0">
        <img src="images/banner/Jam.jpg" className="img-fluid banner"/>
        
      </div>
    </section>
    
    <main id="main">
      <div className="section-title" data-aos="fade-up">
        <h2>All Fruit Jam</h2>
      </div>
     
      <section id="featured" className="featured">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-4" >
              <a href="#exampleModal" data-toggle="modal" className="test">
                <img alt="picture" src="images/Fruitjam/Mixfruitjam.jpg"
                className="img-fluid resize rounded"/>
                <h3 className="text-center my-3 text-uppercase"> Mix Jam </h3>
                <ul className="d-none">
                  <li>Mixture of delicious fruits</li>
                  <li>It does not have fat and cholesterol which helps to gain a healthy weight.</li>
                  <li>The pectin helps to lower the chances of cancer and promotes the health of skin, hair, bones and finger nails.</li>
                  <li>Country of Origin- India</li>
                  <li>Min Order Qty- 1 Mt Ton</li>
                </ul>
              </a>
            </div>
             
          </div>
          </div>
          </section>
          <Commanmodel />           
          </main>
        </>
    );

}

export default Fruitjam;