 
import $ from 'jquery'; 
import { useEffect } from "react";
import Commanmodel from "./Allproductmodel";
import './AllProduct.css';

const Pulses = () =>{

    useEffect(()=>{
        $('.test').on('click', function()
        {
           
        let element = $(this);        
        var img =  $(this).find('img').attr('src');
        var head =  $(this).find('h3').text();
        var data =  $(this).find('ul').html();
        var p_d = $(this).find('.p_def').html();       
        $('.modal-title').text(head);
        $('#model_img').attr('src',img);
        $('.model_data').html(data);        
        });
  
    },[])

    return(
        <>
            <section>
      <div className="container-fluid p-0">
        <img src="images/banner/chana-390705_1920.jpg" className="img-fluid banner"/>
        
      </div>
    </section>
    
    <main id="main">
      <div className="section-title" data-aos="fade-up">
        <h2>All Pulses</h2>
      </div>
     
      <section id="featured" className="featured">
        <div className="container">
          <div className="row">           
            <div className="col-sm-12 col-md-4" >
              <a href="#exampleModal" data-toggle="modal" className="test">
                <img alt="picture" src="images/pulses/toordal.jpg"
                className="img-fluid resize rounded"/>
                <h3 className="text-center my-3 text-uppercase"> Toor Dal </h3>
                <ul className="d-none">
                  <li>Toor Dal not only increases the taste of your meals but also provided all kinds of nutrients.</li>
                  <li>Nutrition Fact(Per 100gm)- Potassium 1,392mg- 39%</li><li>Total Carbohydrate 63g- 21%</li><li>Dietary fiber 15g- 60%</li><li>Protein 22g- 44%</li><li>Calcium- 13%</li><li>Iron- 28%</li><li>Vitamin B6- 15%</li><li>Magnesium- 45%</li>
                  <li>Country of Origin-India</li>
                  <li>Min Order Qty- 1Mt Ton</li>
                </ul>
              </a>
            </div>            
          </div>
          </div>
          </section>
          <Commanmodel /> 
          </main>
        </>
    );
}

export default Pulses;