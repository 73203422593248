import Product from "./Productarray";
const Products=() =>{
   
  let product_data =[
    {
        link:'/fruit',
        img:'images/banner/Fruits.jpg',
        name:'Fruits'
    },
    {
        link:'/Vegetables',
        img:'images/banner/Vegetables.jpg',
        name:'Vegetables'
    },
    {
        link:'/spices',
        img:'images/banner/Spices.png',
        name:'Spices'
    },
    {
        link:'/fjam',
        img:'images/banner/Jam.jpg',
        name:'Fruit jam'
    },
    {
        link:'/pulses',
        img:'images/banner/toor-dhal-1750920_1920-removebg-preview.png',
        name:'Pulses'
    },
    {
        link:'/sauce',
        img:'images/banner/sauce-3327933_1920.png',
        name:'Sauces'
    },        
    {
        link:'/pickles',
        img:'images/banner/pickles.jpg',
        name:'Pickles'
    },
    {
        link:'/legume',
        img:'images/banner/legumes-beans.png',
        name:'Legume'
    },
    {
        link:'/cereals',
        img:'images/banner/IMG4preview.png',
        name:'Cereals'
    }
]
    return(
      <section>
          <div className="text-center h3 font-weight-bold mb-4 text-uppercase">All Products</div>       
          <section id="featured" className="featured">
            <div className="container">
              <div className="row">  
                      {
                        product_data.map((item,i)=>{
                        return <Product                
                        link={item.link} 
                        imgsrc={item.img} 
                        title={item.name}>          
                        </Product>
                        })
                      }     
                
                {/* <Product 
                link={product_data[1].link} 
                imgsrc={product_data[1].img} 
                title={product_data[1].name}>          
                </Product>
                <Product 
                link={product_data[2].link} 
                imgsrc={product_data[2].img} 
                title={product_data[2].name}>          
                </Product>
                <Product 
                link={product_data[3].link} 
                imgsrc={product_data[3].img} 
                title={product_data[3].name}>          
                </Product>
                <Product 
                link={product_data[4].link} 
                imgsrc={product_data[4].img} 
                title={product_data[4].name}>          
                </Product>
                <Product 
                link={product_data[5].link} 
                imgsrc={product_data[5].img} 
                title={product_data[5].name}>          
                </Product>
                <Product 
                link={product_data[6].link} 
                imgsrc={product_data[6].img} 
                title={product_data[6].name}>          
                </Product>
                <Product 
                link={product_data[7].link} 
                imgsrc={product_data[7].img} 
                title={product_data[7].name}>          
                </Product>
                <Product 
                link={product_data[8].link} 
                imgsrc={product_data[8].img} 
                title={product_data[8].name}>          
    </Product> */}
              </div>
            </div>
          </section>  
      </section>
);
}

export default Products;