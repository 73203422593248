
 
import $ from 'jquery'; 
import { useEffect } from "react";
import Commanmodel from "./Allproductmodel";
import './AllProduct.css';
 
const Fruit= () => {
    useEffect(()=>{
        $('.test').on('click', function()
        {
        let element = $(this);        
        var img =  $(this).find('img').attr('src');
        var head =  $(this).find('h3').text();
        var data =  $(this).find('ul').html();
        var p_d = $(this).find('.p_def').html();       
        $('.modal-title').text(head);
        $('#model_img').attr('src',img);
        $('.model_data').html(data);        
        });
  
    },[])
    return(       
       
        <>
            <section>
                <div className="container-fluid p-0">
                    <img src="images/banner/Fruits12345.jpg" className="img-fluid banner"/>
                    
                </div>
            </section>
            <main id="main">
        <div className="section-title" data-aos="fade-up">
            <h2>All Fruits</h2>
        </div>
        
        <section id="featured" className="featured">
            <div className="container">
            <div className="row">
                <div className="col-sm-12 col-md-4" >
                <a href="#exampleModal" data-toggle="modal" className="test">
                    <img alt="picture" src="images/fruit/Mango.jpg"
                    className="img-fluid resize rounded"/>
                    <h3 className="text-center my-3 text-uppercase"> Mango </h3>
                    <ul className="d-none text-warning">
                    <li>The Alphonso is a seasonal fruit, available mid-April through the end of June.</li>
                    <li className="">Nutrition Fact(Per 100 gm )</li>
                    <li className="">Carbs- 16g</li>
                    <li className="">Dietary Fiber- 1g</li>
                    <li className="">Sugar- 14g</li>
                    <li className="">Vitamin A- 4%</li>
                    <li className="">Vitamin C- 46%</li>
                    <li className="">Calcium- 1%</li>
                    <li className="">Iron- 1%</li>
                    <li className="">Country of Origin- India</li>
                    <li className="">Min Order Qty- 1 Mt Ton</li>
                    </ul>
                </a>
                </div>
                <div className="col-sm-12 col-md-4" >
                <a href="#exampleModal" data-toggle="modal" className="test">
                    <img alt="picture" src="images/fruit/banana.jpg"
                    className="img-fluid resize rounded"/>
                    <h3 className="text-center my-3 text-uppercase"> banana </h3>
                    <ul className="d-none">
                    <li>Bananas are High in Potassium and Pectin</li>
                    <li>Nutrition Fact(Per 100 gm)</li>
                    <li>Potassium 358mg-10%</li><li>Carbohydrate 23g- 7%</li>
                    <li>Dietary fiber 2.6g- 10%</li><li>Vitamin A- 1%</li>
                    <li>Vitamin C- 14%</li><li>Iron- 1%</li>
                    <li>Vitamin B6- 20%</li><li>Magnesium- 6%</li>
                    <li>Country of Origin- India</li><li>Min Order Qty- 1Mt Ton</li>
                    </ul>
                </a>
                </div>
                <div className="col-sm-12 col-md-4" >
                <a href="#exampleModal" data-toggle="modal" className="test">
                    <img alt="picture" src="images/fruit/orange.jpg"
                    className="img-fluid resize rounded" />
                    <h3 className="text-center my-3 text-uppercase"> Orange </h3>
                    <ul className="d-none">
                    <li>Oranges are primarily made up of carbs and water. They’re also a good source of fiber, which may support digestive health.</li>   <li>A number of vitamins and minerals are found in oranges, including vitamin C, thiamine, folate, and potassium.</li>
                    <li>Nutrition Fact(Per 100gm)- Vitamin A- 4%</li><li>Vitamin C- 88%</li><li>Vitamin B6- 5%</li><li>Magnesium- 2%</li><li>Calcium- 4%</li><li>Potassium 181mg- 5%</li><li>Dietary fiber 2.4g- 9%</li> <li>Carbohydrate 12g -4%</li><li>Country of Origin- India</li><li>Min Order Qty- 1Mt Ton</li>
                    </ul>
                </a>
                </div>
                <div className="col-sm-12 col-md-4" >
                <a href="#exampleModal" data-toggle="modal" className="test">
                    <img alt="picture" src="images/fruit/Dalimb.jpg"
                    className="img-fluid resize rounded"/>
                    <h3 className="text-center my-3 text-uppercase">Pomegranate </h3>
                    <ul className="d-none">
                    <li>Pomegranate has anti-oxidant, anti-viral and anti-tumor properties and is said to be a good source of vitamins.</li>
                        <li>Nutrition Fact(Per 100gm)- Potassium 236mg- 6%</li><li>Total Carbohydrate 19g- 6%</li><li>Dietary fiber 4g- 16%</li><li>Vitamin C- 17%</li><li>Vitamin B6- 5%</li><li>Magnesium- 3%</li><li>iron- 1%</li><li>Country of Origin- India</li>
                        <li>Min Order Qty- 1Mt Ton</li>
                    </ul>
                    </a>
                </div>
                <div className="col-sm-12 col-md-4" >
                    <a href="#exampleModal" data-toggle="modal" className="test">
                    <img alt="picture" src="images/fruit/grapes.jpg"
                    className="img-fluid resize rounded"/>
                    <h3 className="text-center my-3 text-uppercase">Grapes </h3>
                    <ul className="d-none">
                        <li>Grapes contain many important vitamins and minerals, including more than one-quarter of the RDIs for vitamins C </li>
                        <li>Nutrition Fact(Per 100gm)- Vitamin A -2%</li><li>Vitamin C- 6%</li><li>Vitamin B6- 5%</li><li>Magnesium- 1%</li><li>Potassium 191 mg- 5%</li><li>Total Carbohydrate 17g- 5%</li><li>Country of Origin- India</li><li>Min Order Qty- 1Mt Ton</li>
                    </ul>
                    </a>
                </div>
                
                </div>
                </div>
                </section>            
                <Commanmodel /> 
            </main>
        </>
    );
}

export default Fruit;