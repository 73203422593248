
import { NavLink } from "react-router-dom";

const Header = () =>{
    return(
      <header id="header">
      <div className="container d-flex">
        <div className="logo mr-auto">           
          <NavLink to="/"><img src="../assets/img/LOGO.svg" alt="" className="img-fluid"/></NavLink>
        </div>
        <nav className="nav-menu d-none d-lg-block">
          <ul>
            <li >
            <NavLink to="/">Home</NavLink>
            </li>
            <li className={({ isActive }) => (isActive ? 'active' : 'inactive')}> <NavLink to="/about" >About Us</NavLink>
            </li>
            <li className="drop-down"><a href="#">Products</a>
            
            <ul>
              <li>
              <NavLink to="/fruit">Fruits</NavLink>
              </li>
              <li> <NavLink to="/Vegetables">Vegetables</NavLink></li>
              <li> <NavLink to="/spices">Spices</NavLink></li>
              <li> <NavLink to="/fjam">Fruit jam</NavLink></li>
              <li> <NavLink to="/pulses">Pulses</NavLink></li>
              <li> <NavLink to="/sauce">Sauce</NavLink></li>
              <li> <NavLink to="/pickles">Pickles</NavLink></li>
              <li> <NavLink to="/legume">Legume</NavLink></li>
              <li> <NavLink to="/cereals">Cereals</NavLink></li>         
          </ul>
        </li>             
      </ul>
      </nav> 
    </div>
    </header>
    );
}

export default Header;