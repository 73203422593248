import { Link } from "react-router-dom";
// import { FaFacebook , FaLinkedin, FaInstagram } from "react-icons/fa";
const Footer = () =>{
    return (
         <section className="p-0">
            <footer id="footer">
                <div className="footer-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-6 footer-links">
                            <h4>General Links</h4>
                                <ul className="">
                                    <li><i className="bx bx-chevron-right"></i> <Link to="/">Home</Link></li>
                                    <li><i className="bx bx-chevron-right"></i> <Link to="/about">About Us</Link></li>
                                    <li><i className="bx bx-chevron-right"></i> <Link to="/Services">Services</Link></li>                       
                                    <li><i className="bx bx-chevron-right"></i> <Link to="/Contact">Contact Us</Link></li>
                                </ul>
                            </div>
                            <div className="col-lg-3 col-md-6 footer-links">
                                <h4>Products</h4>
                                <ul class="">
                                    <li><i className="bx bx-chevron-right"></i> <Link to="/fruit">Fruits</Link></li>
                                    <li><i className="bx bx-chevron-right"></i> <Link to="/Vegetables">Vegetables</Link></li>
                                    <li><i className="bx bx-chevron-right"></i> <Link to="/spices">Spices</Link></li>
                                    <li><i className="bx bx-chevron-right"></i> <Link to="/fjam">Fruit jam </Link></li>
                                    <li><i className="bx bx-chevron-right"></i> <Link to="/pulses">Pulses</Link></li>
                                </ul>
                            </div>
                            <div className="col-lg-3 col-md-6 footer-links">
                                <h4>Products</h4>
                                <ul>                        
                                    <li><i className="bx bx-chevron-right"></i> <Link to="/sauce">Sauce</Link></li>
                                    <li><i className="bx bx-chevron-right"></i> <Link to="/pickles">Pickles</Link></li>
                                    <li><i className="bx bx-chevron-right"></i> <Link to="/legume">Legume</Link></li>
                                    <li><i className="bx bx-chevron-right"></i> <Link to="/cereals">Cereals</Link></li>
                                </ul>
                            </div>
                            <div className="col-lg-3 col-md-6 footer-contact">
                                <h4>Contact Us</h4>
                            <p>
                                At/Post-Undale,<br/>
                                Tal-Karad,Dist-Satara <br/>
                                Pin Code-415111 <br/>
                                
                                <strong>Phone:</strong> +91 9657 257 246<br/>
                                <strong>Email:</strong> avighnaexport@gmail.com<br/>
                            </p>
                                <div className="social-links mt-3">
                                 
                                {/* <Link to="#" className="instagram"><FaLinkedin /></Link>                        
                                <Link to="#" className="linkedin"><FaInstagram /></Link>                      */}
                                <Link to="https://www.facebook.com/Avighna-Exim-106584507930126" target="_blank" className="facebook"><i class="bx bxl-facebook"></i></Link> 
                                     <Link to="#" className="instagram"><i className="bx bxl-instagram"></i></Link>                        
                                     <Link to="#" className="linkedin"><i className="bx bxl-linkedin"></i></Link>
                                </div>
                                </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="copyright">
                        &copy; Copyright <strong><span>Avighna Exim</span></strong>. All Rights Reserved
                    </div>                 
              </div>
            </footer>
            <a href="#" className="back-to-top"><i className="icofont-simple-up"></i></a>
        </section>           
    );
}

export default Footer;
