import $ from 'jquery'; 
import { useEffect } from "react";
import Commanmodel from "./Allproductmodel";
import './AllProduct.css';

const Spices = ()=>{
    useEffect(()=>{
        $('.test').on('click',function()
        {
        let element = $(this);        
        var img =  $(this).find('img').attr('src');
        var head =  $(this).find('h3').text();
        var data =  $(this).find('ul').html();
        var p_d = $(this).find('.p_def').html();       
        $('.modal-title').text(head);
        $('#model_img').attr('src',img);
        $('.model_data').html(data);        
        });
  
    },[])

    return(
        <>
        <section>
      <div className="container-fluid p-0" >
        <img src="images/banner/Spices12345.jpg" className="img-fluid banner"/>
        
      </div>
    </section>
    
    <main id="main">
      <div className="section-title" data-aos="fade-up">
        <h2 className="text-uppercase">All Spices</h2>
      </div>
      
      <section id="featured" className="featured">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-4" >
              <a href="#exampleModal" data-toggle="modal" className="test">
                <img alt="picture" src="images/Spices/Dhanapowder.jpg"
                className="img-fluid resize rounded"/>
                <h3 className="text-center my-3 text-uppercase"> Coriander Powder </h3>
                <ul className="d-none">                  
                  <li>Coriander is full of antioxidants that demonstrate immune-boosting, anticancer, anti-inflammatory and neuroprotective effects.</li>
                  <li> A spice-rich diet appears to be associated with a lower risk of heart disease.</li><li>Country of Origin- India</li>
                  <li>Min Order Qty- 1Mt Ton</li>
                </ul>
              </a>
            </div>
            <div className="col-sm-12 col-md-4" >
              <a href="#exampleModal" data-toggle="modal" className="test">
                <img alt="picture" src="images/Spices/Haldipowder.png"
                className="img-fluid resize rounded"/>
                <h3 className="text-center my-3 text-uppercase">Turmeric Powder </h3>
                <ul className="d-none">
                  <li>Turmeric Powder is not only used for its flavour and aroma but also for its health benefits</li>
                  <li>Nutrition Fact(Per 100gm)- Total Carbohydrate 67g-24%</li><li> Dietary Fiber 23g- 82%</li><li>Calcium 168mg- 13%</li><li>Iron 55mg- 306%</li><li>Potassium 2080mg- 44%</li><li>Protein 9.7g- 19%</li>
                  <li>Country of Origin- India</li>
                  <li>Min Order Qty- 1Mt Ton</li>
                </ul>
              </a>
            </div>
            <div className="col-sm-12 col-md-4" >
              <a href="#exampleModal" data-toggle="modal" className="test">
                <img alt="picture" src="images/Spices/mirchipowder.jpg"
                className="img-fluid resize rounded"/>
                <h3 className="text-center my-3 text-uppercase"> Chilli Powder </h3>
                <ul className="d-none">
                  <li>Chilli powder is used to turn any simple dish into a hot and spicy one</li>
                  <li>Chilli powder is basically the spice blend which is made with ground dried chilies, oregano, cumin, and a hint of garlic powder that provides the food with its spiced and smoked taste</li>                 
                   <li>Country of Origin- India</li>
                  <li>Min Order Qty- 1Mt Ton</li>
                </ul>
              </a>
            </div>          
          </div>
          </div>
          </section>
                   
             <Commanmodel /> 
          </main>
        </>
    );
}

export default Spices;