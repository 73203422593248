import './banner.css';

function About(){
    return(       
      <div id="about_page">
        <section className="" id="hero">
      <div className="hero-container" >
        <div id="heroCarousel" className="carousel slide carousel-fade" data-ride="carousel">
        <ol className="carousel-indicators" id="hero-carousel-indicators"></ol>
        <div className="carousel-inner" role="listbox">
        
          <div className="carousel-item banner1 active" >
            <div className="carousel-container">
              <div className="carousel-content">
                <h2 className="animate__animated animate__fadeInDown">Welcome to <span>Avighna Exim</span></h2>
                <p className="animate__animated animate__fadeInUp">Avighna Exim is a Export-Import company that deals in the business of Good Quality Agricultural and Other Products.</p>                
              </div>
            </div>
          </div>
          
          <div className="carousel-item banner2" >
            <div className="carousel-container">
              <div className="carousel-content">
                <h2 className="animate__animated fanimate__adeInDown">Avighna Exim <span>For Farmers</span></h2>
                <p className="animate__animated animate__fadeInUp">We provide guidance to farmers for export their goods in terms of market selection, buyer finding, packaging and documentation.</p>                
              </div>
            </div>
          </div>
         
          <div className="carousel-item banner3" >
            <div className="carousel-container">
              <div className="carousel-content">
                <h2 className="animate__animated animate__fadeInDown">Avighna Exim <span>Services</span></h2>
                <p className="animate__animated animate__fadeInUp">We provide Basic Business set up services like Shop act Registration, Udyog Adhar Registration, GSTIN Registration, RCMC Certification, IEC Certificate.</p>                
              </div>
            </div>
          </div>
        </div>
        <a className="carousel-control-prev" href="#heroCarousel" role="button" data-slide="prev">
          <span className="carousel-control-prev-icon icofont-rounded-left" aria-hidden="true"></span>
          <span className="sr-only">Previous</span>
        </a>
        <a className="carousel-control-next" href="#heroCarousel" role="button" data-slide="next">
          <span className="carousel-control-next-icon icofont-rounded-right" aria-hidden="true"></span>
          <span className="sr-only">Next</span>
        </a>
      </div>
    </div>
    </section>
    <main id="main">
      <section id="featured" className="featured">
        <div className="container">
          <div className=" section-title text-center"><h2>About Us</h2></div>
          <p className="lead">
            <b>Avighna Exim </b> is a company that deals in the business of Good Quality Agricultural Product.</p>
            <p className="lead" >
           We are committed to procure good quality products by Educating Safety & Quality Parameters to manufacturer, supplier in the procurement process.</p>
            <p className="lead" >
            We are the exporter and supplier of fresh vegetables, fresh fruits, sauces, fruit jams, pulses, cereals, legumes, Spices, rice and many other similar products. 
          </p>
          <p className="lead font-weight-bold">Customer Satisfaction</p>
          <p className="lead" >Customer satisfaction is main motive of our company. we provide best quality products to suit international standards and norms.</p>
          <p className="lead font-weight-bold">Why Choose Us?</p>
          <ul>
            <li>Best Quality Product</li>
            <li>On time & Safe Delivery</li>
            <li>Payment Assurity</li>
          </ul>
        </div>
        </section>
         
        <section id="services" className="services">
          <div className="container">
            <div className="section-title" data-aos="fade-up">
              <h2>Our Services</h2>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                <div className="icon-box">
                  <div className="icon"><img src="assets/img/shop.png" className="w-100" /></div>
                  <h4><a href="">Shop Act Registration</a></h4>
                  <p>Shop Act License is a type of Legal permission which legally allows us to do business in a particular place... It gives you a proof of a particular business being done at that place.</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
                <div className="icon-box">
                  <div className="icon"><img src="assets/img/Udyogadhar.png" className="w-100"/></div>
                  <h4><a href="">Udyog Adhar Registration</a></h4>
                  <p>Udyog Aadhaar (also called as Aadhaar for Business) is a unique 12-digit Government identification number provided by the Ministry of MSME for the small and medium enterprises to register themselves as MSME.</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0">
                <div className="icon-box">
                  <div className="icon"><img src="assets/img/register123.jpg" className="w-100"/></div>
                  <h4><a href="">GSTIN Registration</a></h4>
                  <p>According to GST rules, it is mandatory for a business that has a turnover of above Rs.40 lakh to register as a normal taxable entity. This is referred to as the GST registration process. 
                </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
                <div className="icon-box">
                  <div className="icon"><img src="assets/img/RCMC123.png" className="w-100"/></div>
                  <h4><a href="">RCMC Certification</a></h4>
                  <p>As per the Foreign Trade Policy, an exporter is required to get a Registration-cum-Membership Certificate (RCMC) for availing various benefits under the Policy. For registration purposes, FIEO has been recognized by the Government as an Export Promotion Council.
                </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
                <div className="icon-box">
                  <div className="icon"><img src="assets/img/iec12.png" className="w-100"/></div>
                  <h4><a href="">IEC Certificate</a></h4>
                  <p>Import Export Code (IEC) is a ten digit code issued by Director General of Foreign Trade (DGFT) to all those who are engaged in the business of Import and Export of goods.</p>
                </div>
              </div>
              
            </div>
          </div>
          </section>
          
          </main>
      </div>
    );
}

export default About;