import Header from "./Components/header";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Fruit from "./Pages/Fruit";
import Vegetables from "./Pages/vegetables";
import Spices from "./Pages/Spices";
import Fjam from "./Pages/Fjam";
import Pulses from "./Pages/Pulses";
import Sauce from "./Pages/Sauce";
import Pickles from "./Pages/Pickles";
import Legume from "./Pages/Legume";
import Cereals from "./Pages/Cereals";
import Footer from "./Components/footer";


import {Routes, Route } from "react-router-dom";

function App() {
  return (
     <div>
      <Header/>
       
        <Routes>
          <Route path="/" element={<Home />} />  
          <Route path="about" element={<About />} />
          <Route path="fruit" element={<Fruit />} />
          <Route path="Vegetables" element={<Vegetables />} />
          <Route path="spices" element={<Spices />} />
          <Route path="fjam" element={<Fjam />} />
          <Route path="pulses" element={<Pulses />} />
          <Route path="sauce" element={<Sauce />} />
          <Route path="pickles" element={<Pickles />} />
          <Route path="legume" element={<Legume />} />
          <Route path="cereals" element={<Cereals />} />

        </Routes>       
      <Footer />

     </div>
  );
}

export default App;
