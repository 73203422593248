
 
import $ from 'jquery'; 
import { useEffect } from "react";
import Commanmodel from "./Allproductmodel";
import './AllProduct.css';
 
const Vegetables= () => {
    useEffect(()=>{
      $('.test').on('click',function()
        {
        let element = $(this);        
        var img =  $(this).find('img').attr('src');
        var head =  $(this).find('h3').text();
        var data =  $(this).find('ul').html();
        var p_d = $(this).find('.p_def').html();       
        $('.modal-title').text(head);
        $('#model_img').attr('src',img);
        $('.model_data').html(data);        
        });
  
    },[])
    return(       
       
        <>
             <section>
      <div className="container-fluid p-0" >
        <img src="images/banner/vegetables.webp" className="img-fluid banner"/>
        
      </div>
    </section>
    
    <main id="main">
      <div className="section-title" data-aos="fade-up">
        <h2>All Vegetables</h2>
      </div>      
      <section id="featured" className="featured">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-4" >
              <a href="#exampleModal" data-toggle="modal" className="test">
                <img alt="picture" src="images/Vegetables/tomato.jpg"
                className="img-fluid resize rounded"/>
                <h3 className="text-center my-3 text-uppercase"> Tomato </h3>
                <ul className="d-none">                  
                   <li>Nutrition Fact(Per 100 gm)- Carbohydrates- 3.9g</li>
                   <li>Sugars- 2.6g</li><li>Dietary fiber- 1.2g</li><li>Thiamine(B1)0.037mg- 3%</li><li>Niacin(B3)0.594mg- 4%</li><li>Vitamin B6 0.08mg- 6%</li><li>Vitamin C 14mg- 17%</li><li>Vitamin E 0.54mg- 4%</li><li>Vitamin K 7.9g- 8%</li><li>Magnesium 11mg- 3%</li><li>Manganese 0.114mg- 5%</li><li>Phosphorus 24mg- 3%</li><li>Potassium237mg- 5%</li><li>Country of Origin- India</li>
                   <li>Min Order Qty- 1Mt Ton</li>
                </ul>
              </a>
            </div>
            <div className="col-sm-12 col-md-4" >
              <a href="#exampleModal" data-toggle="modal" className="test">
                <img alt="picture" src="images/Vegetables/cabbages.jpg"
                className="img-fluid resize rounded"/>
                <h3 className="text-center my-3 text-uppercase"> Cabbage </h3>
                <ul className="d-none">
                  <li>Cabbage is a low-calorie vegetable that is rich in vitamins, minerals and antioxidants.</li>
                  <li>Nutrition Fact(Per 100 gm)-Vitamin A- 1%</li><li>Vitamin C- 60%</li><li>Calcium- 4%</li><li>Iron- 2%</li><li>Vitamin B6- 5%</li><li>Magnesium- 3%</li><li>Potassium 170mg- 4%</li><li>Total Carbohydrate 6mg- 2%</li><li>Dietary fiber 2.5mg- 10%</li><li>Country of Origin- India</li><li>Min Order Qty- 1Mt Ton</li>
                </ul>
              </a>
            </div>
            <div className="col-sm-12 col-md-4" >
              <a href="#exampleModal" data-toggle="modal" className="test">
                <img alt="picture" src="images/Vegetables/beans.jpg"
                className="img-fluid resize rounded"/>
                <h3 className="text-center my-3 text-uppercase"> Beans </h3>
                <ul className="d-none text-warning">
                   <li>Green Beans are great for Skin, hair and nails</li>
                  <li>Nutrition Fact(Per 100 gm)- Vitamin A- 2%</li>
                  <li>Vitamin C- 27%</li><li>Calcium- 3%</li><li>Iron- 5%</li><li>Vitamin B-65%</li><li>Magnesium- 6%</li><li>Potassium 209mg- 5%</li><li>Carbohydrate 7g- 2%</li><li>Dietary fibre 3.4g- 13%</li>
                  <li>Country of Origin- India</li><li>Min Order Qty- 1Mt Ton</li> 
                </ul>
              </a>
            </div>
              <div className="col-sm-12 col-md-4" >
              <a href="#exampleModal" data-toggle="modal" className="test">
                <img alt="picture" src="images/Vegetables/onion.jpg"
                className="img-fluid resize rounded"/>
                <h3 className="text-center my-3 text-uppercase"> Onion </h3>
                <ul className="d-none">
                  <li className="">Onions are low in calories yet high in nutrients, including vitamin C, B vitamins and potassium.</li>
                  <li>Nutrition Fact(Per 100 gm)- Vitamin C-12%</li><li>Calcium-2%</li><li>Iron- 1%</li><li>Vitamin B6- 5%</li><li>Magnesium- 2%</li>
                  <li>Potassium 146mg- 4%</li><li>Total Carbohydrate 9g- 3%</li><li> Dietary fiber 1.7g- 6%</li><li>Country of Origin- India</li><li>Min Order Qty- 1Mt Ton</li>
                </ul>
              </a>
            </div> 
           
          </div>
          </div>
          </section>        
            
            <Commanmodel/>        
          </main>
        </>
    );
}

export default Vegetables;